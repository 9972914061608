import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { classNames, unwindObject } from '../../utils';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { HelpTooltip } from '../HelpTooltip';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'className'> {
  name: string;
  label?: string;
  optional?: boolean;
  helpLabel?: string;
  leadingText?: string;
  trailingText?: string;
  condensed?: boolean;
  tooltip?: string;
  showValidIcon?: boolean;
  dataList?: { label?: string; value: any }[];
}

export function FormInput({
  name,
  label,
  optional,
  helpLabel,
  leadingText,
  trailingText,
  condensed = false,
  tooltip,
  showValidIcon = false,
  dataList,
  ...rest
}: InputProps): JSX.Element {
  const methods = useFormContext();
  const errorName = unwindObject(methods.formState.errors, name);
  return (
    <div
      className={classNames(
        condensed ? 'my-1' : 'my-4',
        rest.disabled ? 'opacity-60 cursor-not-allowed' : '',
      )}
    >
      <div className={classNames(optional ? 'flex justify-between' : '')}>
        {label && (
          <label
            htmlFor={name}
            className="flex justify-between text-sm font-medium text-gray-700"
          >
            <span
              className={classNames(
                rest.required ? 'is-required' : '',
                'block',
              )}
            >
              {label}
            </span>
            {tooltip && <HelpTooltip value={tooltip} place="left" />}
          </label>
        )}
        {optional && <span className="text-sm text-gray-500">Optional</span>}
      </div>
      <div
        className={classNames(
          leadingText ? 'flex' : 'relative',
          trailingText ? 'flex' : 'relative',
          'mt-1 rounded-md shadow-sm',
        )}
      >
        {leadingText && (
          <span className="items-center hidden px-3 text-gray-500 border border-r-0 border-gray-300 sm:inline-flex rounded-l-md bg-gray-50 sm:text-sm">
            {leadingText}
          </span>
        )}
        <div className="relative w-full">
          <input
            id={name}
            {...methods.register(name, {
              valueAsNumber: rest.type === 'number',
            })}
            {...(dataList && dataList.length > 0
              ? { list: `${name}-datalist` }
              : {})}
            {...rest}
            className={classNames(
              leadingText && !trailingText ? 'rounded-none rounded-r-md' : '',
              rest.disabled ? 'cursor-not-allowed' : '',
              trailingText && !leadingText ? 'rounded-none rounded-l-md' : '',
              !leadingText && !trailingText ? 'rounded-md ' : 'rounded-none',
              errorName
                ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                : 'focus:ring-indigo-500 focus:border-indigo-500',
              'appearance-none block w-full px-3 py-2 border border-gray-300  shadow-sm placeholder-gray-400 focus:outline-none  sm:text-sm',
            )}
          />
          {dataList && dataList.length > 0 && (
            <datalist id={`${name}-datalist`}>
              {dataList.map((option, optionIdx) => (
                <option key={optionIdx} className="flex" value={option.value}>
                  {option.label || option.value}
                </option>
              ))}
            </datalist>
          )}
          <div
            className={classNames(
              rest.type === 'number' || rest.type === 'date'
                ? 'right-5'
                : 'right-0 ',
              'absolute inset-y-0 flex items-center pr-3 pointer-events-none',
            )}
          >
            <ExclamationCircleIcon
              className={classNames(
                errorName ? 'opacity-100' : '',
                'w-5 h-5 text-red-500 transition-opacity duration-500 opacity-0',
              )}
              aria-hidden="true"
            />
            {!errorName && showValidIcon && methods.formState.isSubmitted && (
              <CheckCircleIcon
                className={classNames(
                  !errorName && showValidIcon && methods.formState.isSubmitted
                    ? 'opacity-100'
                    : '',
                  'w-5 h-5 text-green-500 transition-opacity duration-500 opacity-0',
                )}
                aria-hidden="true"
              />
            )}
          </div>
        </div>
        {trailingText && (
          <span className="items-center hidden px-3 text-gray-500 border border-l-0 border-gray-300 sm:inline-flex rounded-r-md bg-gray-50 sm:text-sm">
            {trailingText}
          </span>
        )}
      </div>
      {helpLabel && !errorName && (
        <p
          className={classNames(
            condensed ? 'mt-1' : 'mt-2',
            'text-sm text-gray-500',
          )}
        >
          {helpLabel}
        </p>
      )}
      <p
        className={classNames(
          errorName ? 'opacity-100 translate-y-3 mb-0' : '',
          'text-sm text-red-600 opacity-0 transform transition-all duration-700 -mt-3',
        )}
      >
        {errorName && errorName.message}
      </p>
    </div>
  );
}

export default FormInput;
