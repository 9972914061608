import { LocationMarkerIcon, PhoneIcon } from "@heroicons/react/solid";

export const locations = [
    {
        title: 'In-person meeting',
        icone: <LocationMarkerIcon className="w-5 h-5 text-pink-500" />,
        value: 'INPERSON'
    },
    {
        title: 'Phone call',
        icone: <PhoneIcon className="w-5 h-5 text-blue-300" />,
        value: 'PHONE'
    },
]

export const locationTypes = {
    phone: 'PHONE',
    inPerson: 'INPERSON',
    userCallsGuest: 'USERCALLSGUEST',
    guestCallsUser: 'GUESTCALLSUSER',
    googleMeet: 'GOOGLEMEET'
  };