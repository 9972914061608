import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './language/en-us.json';
import translationFR from './language/fr-fr.json'; // the translations

var resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};
i18n.use(LanguageDetector).use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources: resources,
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain']
  },
  //lng: defaultLanguage,
  fallbackLng: 'fr',
  keySeparator: '.',
  // to support nested translations
  interpolation: {
    escapeValue: false // react already safes from xss

  }
});
export default i18n;