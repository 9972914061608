import TimezoneSelect from './TimezoneSelect';

export interface ITimezoneOption {
  value: string;
  label: string;
  abbrev?: string;
  altName?: string;
  offset?: number;
}
export declare type ITimezone = ITimezoneOption | string;
export interface TimeZonesProps {
  tz: ITimezone;
  setTz: (tz: any) => void;
  helpLabel?: string;
  label?: string;
}

export const TimeZone = (props: TimeZonesProps) => {
  const {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone,
    setTz,
    helpLabel,
    label,
  } = props;
  return (
    <div className="" data-cy="timezones">
      <div className="bg-white rounded">
        <TimezoneSelect value={tz} onChange={setTz} selectLabel={label} />
      </div>
      {helpLabel && <p className="mt-2 text-sm text-gray-500">{helpLabel}</p>}
    </div>
  );
};
