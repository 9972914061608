import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import React, { Fragment, useState } from 'react';
import { classNames } from '../../utils';
import { locations, locationTypes } from '../../utils/locations';
import { useTranslation } from 'react-i18next';
import { Credential } from '@prisma/client';

export interface LocationInterface {
  title: string;
  icone: JSX.Element | null;
  value: string;
}

export interface ChooseLocationProps {
  onChange?: (location: LocationInterface) => void;
  value?: string;
  selectedLocations?: string[];
  isGuest?: boolean;
  credential?: any;
}

export const ChooseLocation = ({
  onChange,
  value,
  selectedLocations,
  isGuest,
  credential,
}: ChooseLocationProps) => {
  const { t } = useTranslation();
  const locationsFilter = (selectedLocations?: string[]) => {
    const response: LocationInterface[] = [];
    selectedLocations?.forEach((element) => {
      const locationData = [...locations];
      credential?.length > 0 && locationData.push(googleMeetInfos);
      locationData.forEach((location) => {
        location.value === element && response.push(location);
      });
    });
    return response;
  };
  // setCredentialsType(credentialsData.type);
  const googleMeetInfos = {
    title: 'Google meet',
    icone: (
      <img
        src="https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"
        alt="Google meet"
        className="w-5 h-5"
      />
    ),
    value: 'GOOGLEMEET',
  };
  let locationsData =
    !selectedLocations || selectedLocations?.length === 0
      ? [...locations, credential.length > 0 ? googleMeetInfos : locations[0]]
      : locationsFilter(selectedLocations);
  locationsData = locationsData.map((element) => {
    const location = { ...element };
    if (element?.value === locationTypes.inPerson)
      location.title = t('chooseLocation.userLabel');
    if (element?.value === locationTypes.phone)
      location.title = t('chooseLocation.phoneCall');
    return location;
  });
  const findLocation = (value?: string) =>
    locationsData.find((location) => location.value === value);
  const [selected, setSelected] = useState(findLocation(value));
  const handleChange = (location: LocationInterface) => {
    setSelected(location);
    onChange && onChange(location);
  };
  return (
    <Listbox value={selected} onChange={handleChange}>
      <Listbox.Label className="block text-sm font-medium text-gray-700">
        {isGuest
          ? t('chooseLocation.guestLabel')
          : t('chooseLocation.userLabel')}
      </Listbox.Label>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <span className="flex items-center text-gray-600">
            {isGuest
              ? t('chooseLocation.guestDescription')
              : t('chooseLocation.userDescription')}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {locationsData.map((location) => (
              <Listbox.Option
                key={location.value}
                className={({ active }) =>
                  classNames(
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-3 pr-9',
                  )
                }
                value={location}
              >
                {({ selected, active }) => (
                  <>
                    <div className="flex items-center">
                      {location.icone}
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'ml-3 block truncate',
                        )}
                      >
                        {location.title}
                      </span>
                    </div>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-indigo-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        )}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
