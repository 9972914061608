import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, GlobeIcon, SelectorIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import spacetime from 'spacetime';
import timezoneInfo from 'timezone-soft';
import { ITimezone } from '.';
import { classNames } from '../../utils';
import allTimezones from './TimezoneList';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
export let LabelType: any;
(function (LabelType) {
  LabelType['ORIGINAL'] = 'original';
  LabelType['ALTNAME'] = 'altName';
  LabelType['ABBREV'] = 'abbrev';
})(LabelType || (LabelType = {}));

dayjs.extend(utc);
dayjs.extend(timezone);
interface TimezoneSelectProps {
  value: ITimezone;
  onBlur?: any;
  selectLabel?: string;
  onChange: any;
  labelStyle?: string;
  timezones?: any;
  rest?: any;
  showHour?: boolean;
}
const TimezoneSelect = ({
  value,
  onBlur,
  onChange,
  labelStyle = 'original',
  timezones = allTimezones,
  selectLabel,
  showHour = true,
  ...rest
}: TimezoneSelectProps) => {
  const getOptions = React.useMemo(() => {
    return Object.entries(timezones)
      .reduce((selectOptions: ITimezone[], zone) => {
        const now = spacetime.now(zone[0]);
        const tz = now.timezone();
        const tzStrings = timezoneInfo(zone[0]);
        let label = '';
        const abbr: string = now.isDST()
          ? tzStrings[0].daylight?.abbrev
          : tzStrings[0].standard?.abbrev;
        const altName = now.isDST()
          ? tzStrings[0].daylight?.name
          : tzStrings[0].standard?.name;
        const min = tz.current.offset * 60;
        const hr =
          `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
        const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;
        switch (labelStyle) {
          case 'original':
            label = prefix;
            break;
          case 'altName':
            label = `${prefix} ${altName?.length ? `(${altName})` : ''}`;
            break;
          case 'abbrev':
            label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ''}`;
            break;
          default:
            label = `${prefix}`;
        }
        selectOptions.push({
          value: tz.name,
          label: label,
          offset: tz.current.offset,
          abbrev: abbr,
          altName: altName,
        });
        return selectOptions;
      }, [])
      .sort((a: any, b: any) => a.offset - b.offset);
  }, [labelStyle, timezones]);
  const handleChange = (tz: any) => {
    onChange && onChange(tz);
  };
  const findFuzzyTz = (zone: any) => {
    let currentTime = spacetime.now('GMT');
    try {
      currentTime = spacetime.now(zone);
    } catch (err) {
      return;
    }
    return getOptions
      .filter((tz: any) => tz.offset === currentTime.timezone().current.offset)
      .map((tz: any) => {
        let score = 0;
        if (
          currentTime.timezones[tz.value.toLowerCase()] &&
          !!currentTime.timezones[tz.value.toLowerCase()].dst ===
            currentTime.timezone().hasDst
        ) {
          if (
            tz.value
              .toLowerCase()
              .indexOf(
                currentTime.tz.substr(currentTime.tz.indexOf('/') + 1),
              ) !== -1
          ) {
            score += 8;
          }
          if (
            tz.label
              .toLowerCase()
              .indexOf(
                currentTime.tz.substr(currentTime.tz.indexOf('/') + 1),
              ) !== -1
          ) {
            score += 4;
          }
          if (
            tz.value
              .toLowerCase()
              .indexOf(currentTime.tz.substr(0, currentTime.tz.indexOf('/')))
          ) {
            score += 2;
          }
          score += 1;
        } else if (tz.value === 'GMT') {
          score += 1;
        }
        return { tz, score };
      })
      .sort((a: any, b: any) => b.score - a.score)
      .map(({ tz }: any) => tz)[0];
  };
  const parseTimezone = (zone: ITimezone) => {
    if (typeof zone === 'object' && zone.value && zone.label) return zone;
    if (typeof zone === 'string') {
      return (
        getOptions.find((tz: any) => tz.value === zone) ||
        (zone.indexOf('/') !== -1 && findFuzzyTz(zone))
      );
    } else if (zone.value && !zone.label) {
      return getOptions.find((tz: any) => tz.value === zone.value);
    }
  };
  return (
    <Listbox
      as="div"
      value={parseTimezone(value)}
      onChange={handleChange}
      {...rest}
      data-cy="timezones"
    >
      {selectLabel && (
        <Listbox.Label className="flex text-sm font-medium text-gray-700">
          <GlobeIcon className="w-4 h-4 mr-2" />
          {selectLabel}
        </Listbox.Label>
      )}
      <div className="relative mt-1">
        <Listbox.Button
          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          data-cy="timezone"
        >
          <div className="flex">
            <span className="block truncate">
              {parseTimezone(value) && parseTimezone(value).label}
            </span>
            {showHour && (
              <span className={classNames('ml-2 truncate text-gray-500')}>
                {dayjs().tz(parseTimezone(value).value).format('HH:mm')}
              </span>
            )}
          </div>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {getOptions.map((timezone: any, idxTimezone: number) => (
              <Listbox.Option
                key={idxTimezone}
                data-cy={`timezone-${idxTimezone}`}
                className={({ active }) =>
                  classNames(
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-3 pr-9',
                  )
                }
                value={timezone}
              >
                {({ selected, active }) => (
                  <>
                    <div className="flex">
                      <span
                        className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          'truncate',
                        )}
                      >
                        {timezone.label}
                      </span>
                      {showHour && (
                        <span
                          className={classNames(
                            active ? 'text-indigo-200' : 'text-gray-500',
                            'ml-2 truncate',
                          )}
                        >
                          {dayjs().tz(timezone.value).format('HH:mm')}
                        </span>
                      )}
                    </div>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-indigo-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        )}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
export default TimezoneSelect;
