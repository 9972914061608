import { hotjar } from 'react-hotjar';
import { useEffect } from 'react';

export const HotjarInjector = ({ HJID, HJSV }: { HJID: number; HJSV: number }) => {
  useEffect(() => {
    hotjar.initialize(HJID, HJSV);
  }, [HJID, HJSV]);

  return null;
};

export default HotjarInjector;
