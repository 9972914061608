import {
  DestructionConfirmModalState,
  DestructiveConfirmContext,
  InitialConfirmState,
} from '../components/DestructionConfirmModal';
import { useContext } from 'react';

let resolveCallback: any;
export function useConfirm() {
  const { setShow, confirmState, setConfirmState } = useContext(
    DestructiveConfirmContext
  );
  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };
  const isConfirmModal = (
    title = InitialConfirmState.title,
    message = InitialConfirmState.message,
    destroyBtnLabel = InitialConfirmState.destroyBtnLabel,
    isError = InitialConfirmState.isError,
    isWarning = InitialConfirmState.isWarning,
    cancelBtnLabel = InitialConfirmState.cancelBtnLabel
  ) => {
    setConfirmState({
      title,
      message,
      destroyBtnLabel,
      cancelBtnLabel,
      isError,
      isWarning
    } as DestructionConfirmModalState);
    setShow(true);
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = () => {
    setShow(false);
  };

  return { isConfirmModal, onConfirm, onCancel, confirmState };
}
