import './editable-appointment.module.scss';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import {
  Appointment,
  AppointmentReference,
  Attendee,
  CalendarAssignment,
  Payment,
} from '@prisma/client';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import {
  BellIcon,
  CheckIcon,
  InformationCircleIcon,
  RefreshIcon,
  UsersIcon,
  XIcon,
  LocationMarkerIcon,
  HomeIcon,
  PhoneOutgoingIcon,
  UserIcon,
  CreditCardIcon,
  ShareIcon,
  ExclamationIcon,
} from '@heroicons/react/outline';
import { FormattedNumber, IntlProvider } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
/* eslint-disable-next-line */

export enum ViewMode {
  'GUEST_ONLY',
  'HOST_ONLY',
}
export interface EditableAppointmentProps {
  appointment: Appointment & {
    user?: {
      name: string;
      prefersEmail: {
        email: string;
      };
    };
    attendees: Attendee[];
    calendarType: {
      name: string;
      slug: string;
      durationInMinutes: number;
      colorInHexadecimal: string;
      timezone: string | null;
      calendarAssignments: CalendarAssignment[]
    };
    appointmentReferences: AppointmentReference[];
    payment: Payment[];
  };
  isPanelOpen?: boolean;
  viewMode?: ViewMode;
  onCancel: () => void;
  onReschedule: () => void;
  onSendNotification?: () => void;
  onTerminate?: () => void;
  paymentUrlPrefix?: string;
  assignAppointment?: () => void;
}

export function EditableAppointment({
  appointment,
  isPanelOpen = false,
  viewMode,
  onCancel,
  onReschedule,
  onSendNotification,
  onTerminate,
  assignAppointment,
  paymentUrlPrefix,
}: EditableAppointmentProps) {
  const { t, i18n } = useTranslation();
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-between w-full p-4 text-sm font-medium text-left text-gray-900 bg-gray-50 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
            <div className="flex space-x-5 truncate">
              {/* Appointment time */}
              <div className="">
                <span
                  className={classNames(
                    'CANCELLED' === appointment.status
                      ? 'line-through text-indigo-400'
                      : 'text-indigo-600',
                    'text-sm font-semibold ',
                  )}
                >
                  <Moment locale={i18n.language} format="HH:mm">
                    {appointment.startTime}
                  </Moment>{' '}
                  -{' '}
                  <Moment locale={i18n.language} format="HH:mm">
                    {appointment.endTime}
                  </Moment>
                </span>
              </div>
              <div className="flex-grow space-x-2">
                <span
                  style={{
                    backgroundColor: `#${appointment.calendarType.colorInHexadecimal}`,
                  }}
                  className="inline-block w-4 h-4 transition-opacity duration-300 rounded-full"
                ></span>
                <span
                  className={classNames(
                    'CANCELLED' === appointment.status
                      ? 'line-through text-gray-400'
                      : 'text-gray-500',
                    'inline-block  truncate',
                  )}
                >{`${appointment.calendarType.name} - ${appointment.guestName} (${appointment.guestEmail})`}</span>
              </div>
            </div>
            {!isPanelOpen && (
              <ChevronUpIcon
                className={`${
                  open ? 'transform rotate-180' : ''
                } w-5 h-5 text-gray-500`}
              />
            )}
          </Disclosure.Button>
          <Disclosure.Panel
            className="px-4 py-2 text-sm text-gray-500"
            static={isPanelOpen}
          >
            <div className="flex flex-col divide-y">
              <div>
                <dl className="sm:divide-y sm:divide-gray-100">
                  <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-semibold text-gray-800">
                      {t('editableAppointment.bookedOn')}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-2">
                      <div>
                        {dayjs(dayjs(appointment.createdAt))
                          .locale(i18n.language)
                          .format('dddd DD MMMM YYYY')}
                      </div>
                    </dd>
                  </div>
                  <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-semibold text-gray-800">
                      {t('editableAppointment.appointmentDate')}
                    </dt>
                    <dd className="mt-1 text-sm font-semibold text-gray-700 sm:mt-0 sm:col-span-2">
                      <div>
                        {dayjs(dayjs(appointment.startTime))
                          .locale(i18n.language)
                          .format('dddd DD MMMM YYYY')}
                      </div>
                    </dd>
                  </div>
                  <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-semibold text-gray-800">
                      {t('editableAppointment.status')}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-700 sm:mt-0 sm:col-span-2">
                      <span
                        className={classNames(
                          'ACCEPTED' === appointment.status
                            ? 'text-green-800 bg-green-100'
                            : '',
                          'REJECTED' === appointment.status
                            ? 'text-gray-800 bg-gray-100'
                            : '',
                          'CANCELLED' === appointment.status
                            ? 'text-red-800 bg-red-100'
                            : '',
                          'PENDING' === appointment.status
                            ? 'text-yellow-800 bg-yellow-100'
                            : '',
                          'inline-flex px-2 text-xs font-semibold leading-5  rounded-full',
                        )}
                      >
                        {appointment.status}
                      </span>
                    </dd>
                  </div>
                  <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-semibold text-gray-800">
                      <div className="flex items-center mt-2 text-sm sm:mt-0">
                        <LocationMarkerIcon
                          className="flex-shrink-0 mr-1.5 h-4 w-4"
                          aria-hidden="true"
                        />
                        {t('editableAppointment.location')}
                      </div>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-700 sm:mt-0 sm:col-span-2">
                      {appointment.location?.startsWith('inPerson:') && (
                        <div className="flex space-x-1">
                          <HomeIcon className="w-4 h-4" />
                          <span>
                            {appointment.location?.replace('inPerson:', '')}
                          </span>
                        </div>
                      )}
                      {appointment.location?.startsWith('phone:') && (
                        <div className="flex space-x-1 text-blue-500 hover:text-blue-600 hover:underline">
                          <PhoneOutgoingIcon className="w-4 h-4" />
                          <a
                            href={`tel:+${appointment.location?.replace(
                              'phone:',
                              '',
                            )}`}
                          >
                            {`+${appointment.location?.replace('phone:', '')}`}
                          </a>
                        </div>
                      )}
                      {appointment.appointmentReferences.map(
                        (appointmentReference, idx) =>
                          appointmentReference.meetingUrl ||
                          appointmentReference.meetingId ? (
                            <div key={idx} className="flex">
                              {appointmentReference.meetingUrl && (
                                <a
                                  href={appointmentReference.meetingUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-blue-500 truncate hover:underline hover:text-blue-600"
                                >
                                  {appointmentReference.meetingUrl}
                                </a>
                              )}
                            </div>
                          ) : (
                            ''
                          ),
                      )}
                    </dd>
                  </div>
                  {viewMode === ViewMode.GUEST_ONLY && appointment.user && (
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="flex items-center text-sm font-semibold text-gray-800">
                        <UserIcon
                          className="flex-shrink-0 mr-1.5 h-4 w-4"
                          aria-hidden="true"
                        />
                        {t('editableAppointment.organizer')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="flex justify-between text-sm text-gray-700">
                          <span className="truncate">
                            {`${appointment.user.name}`}{' '}
                            <span className="text-gray-500">{`(${appointment.user.prefersEmail.email})`}</span>
                          </span>
                          <span className="flex-shrink-0 ml-4">
                            <a
                              type="button"
                              href={`mailto:${appointment.user.prefersEmail.email}`}
                              className="font-medium text-indigo-600 bg-white rounded-md hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              {t('editableAppointment.sendEmailToGuest')}
                            </a>
                          </span>
                        </div>
                      </dd>
                    </div>
                  )}
                  <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="flex items-center text-sm font-semibold text-gray-800">
                      <UsersIcon
                        className="flex-shrink-0 mr-1.5 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t('editableAppointment.attendees')}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <ul className="space-y-2">
                        {appointment.attendees.map((attendee, idx) => (
                          <li
                            key={idx}
                            className="flex justify-between text-sm text-gray-700"
                          >
                            <span className="truncate">
                              {`${attendee.name}`}{' '}
                              <span className="text-gray-500">{`(${attendee.email})`}</span>
                            </span>
                            <span className="flex-shrink-0 ml-4">
                              <a
                                type="button"
                                href={`mailto:${attendee.email}`}
                                className="font-medium text-indigo-600 bg-white rounded-md hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                {t('editableAppointment.sendEmailToGuest')}
                              </a>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </div>
                  {appointment.rescheduleReason && (
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="flex items-center text-sm font-semibold text-gray-800">
                        <ExclamationIcon
                          className="flex-shrink-0 mr-1.5 h-4 w-4"
                          aria-hidden="true"
                        />
                        {t('editableAppointment.reasonOfrescheduling')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {appointment.rescheduleReason}
                      </dd>
                    </div>
                  )}
                  {appointment.payment.length > 0 && (
                    <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="flex items-center text-sm font-semibold text-gray-800">
                        <CreditCardIcon
                          className="flex-shrink-0 mr-1.5 h-4 w-4"
                          aria-hidden="true"
                        />
                        {t('editableAppointment.payment')}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <ul className="space-y-2">
                          {appointment.payment.map((payment, idx) => (
                            <li
                              key={idx}
                              className="flex justify-between text-sm text-gray-700"
                            >
                              <span className="">
                                <IntlProvider locale="en">
                                  <FormattedNumber
                                    value={payment.amount / 100.0}
                                    // eslint-disable-next-line react/style-prop-object
                                    style="currency"
                                    currency={payment.currency.toUpperCase()}
                                  />
                                </IntlProvider>
                              </span>
                              <span
                                className={classNames(
                                  payment.success
                                    ? 'bg-green-500 text-white'
                                    : payment.refunded
                                    ? 'bg-red-500 text-white'
                                    : 'bg-yellow-300 text-gray-600',
                                  'flex-shrink-0 rounded-xl border px-3 py-1 text-xs font-semibold shadow-md',
                                )}
                              >
                                {payment.success
                                  ? 'PAID'
                                  : payment.refunded
                                  ? 'REFUNDED'
                                  : 'WAITING'}
                              </span>
                              {paymentUrlPrefix && !payment.success && (
                                <span className="flex-shrink-0">
                                  <a
                                    href={`${paymentUrlPrefix}/${payment.uid}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="font-medium text-indigo-600 bg-white rounded-md hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    {t('editableAppointment.payNowLink')}
                                  </a>
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </dd>
                    </div>
                  )}
                  {appointment.additionalInfos &&
                    (appointment.additionalInfos as any[]).filter(
                      (info) => info.answers,
                    ).length > 0 && (
                      <div className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="flex items-center text-sm font-semibold text-gray-800">
                          <InformationCircleIcon
                            className="flex-shrink-0 mr-1.5 h-4 w-4"
                            aria-hidden="true"
                          />
                          {t('editableAppointment.additionalInfos')}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {appointment.additionalInfos &&
                            (appointment.additionalInfos as any[])
                              .filter((info) => info.answers)
                              .map((info: any, idx: any) => {
                                return (
                                  <div
                                    key={idx}
                                    className="flex flex-col flex-wrap"
                                  >
                                    <div className="font-medium">
                                      {info.label}
                                    </div>
                                    {info.type === 'FILE' ? (
                                      <div className="mt-3 text-gray-500">
                                        <a
                                          className="text-blue-500 truncate hover:underline hover:text-blue-600"
                                          href={info.answers}
                                          target="blank"
                                        >
                                          {t('editableAppointment.download')}
                                        </a>
                                      </div>
                                    ) : (
                                      <div className="text-gray-500">
                                        {info.answers}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                        </dd>
                      </div>
                    )}
                </dl>
              </div>
              <div className="flex flex-col justify-end px-4 py-3 space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0">
                {'CANCELLED' !== appointment.status && (
                  <button
                    type="button"
                    onClick={onCancel}
                    className="inline-flex justify-center px-3 py-2 text-sm font-medium leading-4 text-red-500 transition-colors duration-300 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <XIcon className="w-4 h-4 mr-1" />
                    {t('editableAppointment.cancel')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={onReschedule}
                  className="inline-flex justify-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition-colors duration-300 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <RefreshIcon className="w-4 h-4 mr-1" />
                  {t('editableAppointment.reschedule')}
                </button>
                {viewMode === ViewMode.HOST_ONLY &&
                  onSendNotification &&
                  'CANCELLED' !== appointment.status && (
                    <button
                      type="button"
                      onClick={onSendNotification}
                      className="inline-flex justify-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition-colors duration-300 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <BellIcon className="w-4 h-4 mr-1" />
                      {t('editableAppointment.sendReminder')}
                    </button>
                  )}
                {viewMode === ViewMode.HOST_ONLY &&
                  onTerminate &&
                  'ACCEPTED' === appointment.status && (
                    <button
                      type="button"
                      onClick={onTerminate}
                      className="inline-flex justify-center px-3 py-2 text-sm font-medium leading-4 text-green-500 transition-colors duration-300 bg-white border border-green-300 rounded-md shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <CheckIcon className="w-4 h-4 mr-1" />
                      {t('editableAppointment.setToCompleted')}
                    </button>
                  )}
                {viewMode === ViewMode.HOST_ONLY &&
                  appointment.status === 'ACCEPTED' &&
                  appointment.calendarType.calendarAssignments.length > 1 && (
                    <button
                      type="button"
                      onClick={assignAppointment}
                      className="inline-flex justify-center px-3 py-2 text-sm font-medium leading-4 text-yellow-500 transition-colors duration-300 bg-white border border-yellow-300 rounded-md shadow-sm hover:bg-yellow-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <ShareIcon className="w-4 h-4 mr-1" />
                      {t('editableAppointment.assignThisAppointment')}
                    </button>
                  )}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default EditableAppointment;
