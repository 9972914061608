import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useState, useEffect, useCallback } from 'react';
import { classNames } from '../../utils';
import './date-picker.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React from 'react';
import localeData from 'dayjs/plugin/localeData';
import isToday from 'dayjs/plugin/isToday';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(isToday);

/* eslint-disable-next-line */
export interface DatePickerProps {
  weekStart: 'Monday' | 'Sunday';
  onDatePicked?: (date: Dayjs) => void;
  isDayDisabled?: (date: Dayjs) => boolean;
  organizerTimeZone: string;
  date?: Dayjs;
  isLoadingSlots?: boolean;
  periodType: 'range' | 'rolling' | 'unlimited';
  periodStartDate: string;
  onPeriodStartDateChanged?: (selectedMonth: number) => void;
  periodEndDate: string;
}

export function DatePicker({
  weekStart,
  onDatePicked,
  isDayDisabled,
  organizerTimeZone,
  date,
  isLoadingSlots,
  periodType,
  periodStartDate,
  onPeriodStartDateChanged,
  periodEndDate,
}: DatePickerProps) {
  const [days, setDays] = useState<
    ({ disabled: boolean; date: number } | null)[]
  >([]);
  /* The Selected month is initialize inside useEffect */
  const [selectedMonth, setSelectedMonth] = useState<number | null>(
    dayjs(periodStartDate).month(),
  );
  const { t, i18n } = useTranslation();
  // Handle month changes
  const incrementMonth = () => {
    if (selectedMonth != null) {
      setSelectedMonth(selectedMonth + 1);
      onPeriodStartDateChanged && onPeriodStartDateChanged(selectedMonth + 1);
    }
  };
  const decrementMonth = () => {
    if (selectedMonth != null && selectedMonth > 0) {
      setSelectedMonth(selectedMonth - 1);
      onPeriodStartDateChanged && onPeriodStartDateChanged(selectedMonth - 1);
    }
  };
  const inviteeDate = useCallback(
    (): Dayjs => dayjs().month(selectedMonth ?? 0),
    [selectedMonth],
  );

  const disableDay = useCallback(() => {
    let weekdayOfFirst = inviteeDate().date(1).day();
    if (weekStart === 'Monday') {
      weekdayOfFirst -= 1;
      if (weekdayOfFirst < 0) weekdayOfFirst = 6;
    }
    const days = Array(weekdayOfFirst).fill(null);
    const isDisabled = (day: number) => {
      const date: Dayjs = inviteeDate().date(day);
      switch (periodType) {
        case 'range': {
          const periodRangeStartDay = dayjs(periodStartDate)
            .tz(organizerTimeZone)
            .endOf('day');
          const periodRangeEndDay = dayjs(periodEndDate)
            .tz(organizerTimeZone)
            .endOf('day');
          return (
            date.endOf('day').isBefore(dayjs().utcOffset(date.utcOffset())) ||
            date.endOf('day').isBefore(periodRangeStartDay) ||
            date.endOf('day').isAfter(periodRangeEndDay) ||
            (isDayDisabled !== undefined && isDayDisabled(date))
          );
        }
        case 'unlimited':
        default:
          return (
            date.endOf('day').isBefore(dayjs().utcOffset(date.utcOffset())) ||
            (isDayDisabled !== undefined && isDayDisabled(date))
          );
      }
    };
    const daysInMonth = inviteeDate().daysInMonth();
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({ disabled: isDisabled(i), date: i });
    }
    setDays(days);
  }, [
    inviteeDate,
    isDayDisabled,
    organizerTimeZone,
    periodEndDate,
    periodStartDate,
    periodType,
    weekStart,
  ]);

  useEffect(() => {
    // Create placeholder elements for empty days in first week
    disableDay();
  }, [selectedMonth, disableDay, periodStartDate]);
  i18n.language && dayjs.locale(i18n.language.includes('fr') ? 'fr' : 'en');
  return (
    <div
      className={
        'mt-8 sm:mt-0 sm:min-w-[455px] ' +
        (date ? 'w-full sm:dark:border-gray-800 sm:px-1 ' : 'w-full sm:pl-4')
      }
    >
      <div className="flex mb-2 text-lg font-light text-gray-600">
        <span className="w-1/2 text-gray-600 uppercase dark:text-white">
          <strong className="text-gray-900 dark:text-white">
            {inviteeDate().format('MMMM')}
          </strong>
          <span className="text-gray-500"> {inviteeDate().format('YYYY')}</span>
        </span>
        <div className="w-1/2 text-right text-gray-600 dark:text-gray-400">
          <button
            onClick={decrementMonth}
            className={
              'group mr-2 p-1' +
              (selectedMonth != null &&
                selectedMonth <= dayjs().month() &&
                'text-gray-400 dark:text-gray-600')
            }
            disabled={
              selectedMonth != null &&
              selectedMonth <= dayjs().month() &&
              date &&
              dayjs().year() >= date.year()
            }
          >
            <ChevronLeftIcon className="w-5 h-5 group-hover:text-black dark:group-hover:text-white" />
          </button>
          <button className="p-1 group" onClick={incrementMonth}>
            <ChevronRightIcon className="w-5 h-5 group-hover:text-black dark:group-hover:text-white" />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-4 text-center border-t border-b dark:border-gray-800 sm:border-0">
        {dayjs
          .weekdaysShort()
          .sort((a, b) =>
            weekStart.startsWith(a) ? -1 : weekStart.startsWith(b) ? 1 : 0,
          )
          .map((weekDay) => (
            <div
              key={weekDay}
              className="my-2 text-xs tracking-widest text-gray-500 uppercase"
            >
              {weekDay}
            </div>
          ))}
      </div>
      <div
        className={classNames('grid grid-cols-7 gap-2 text-center relative')}
      >
        {isLoadingSlots && (
          <>
            <div className="absolute inset-y-0 z-10 flex justify-center w-full mx-auto rounded-md opacity-50 bg-blue-50"></div>
            <svg
              version="1.1"
              id="L5"
              className="absolute z-20 pl-24"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enableBackground="new 0 0 0 0"
            >
              <circle fill="#93c5fd" stroke="none" cx="6" cy="50" r="6">
                <animateTransform
                  attributeName="transform"
                  dur="1s"
                  type="translate"
                  values="0 15 ; 0 -15; 0 15"
                  repeatCount="indefinite"
                  begin="0.1"
                />
              </circle>
              <circle fill="#93c5fd" stroke="none" cx="30" cy="50" r="6">
                <animateTransform
                  attributeName="transform"
                  dur="1s"
                  type="translate"
                  values="0 10 ; 0 -10; 0 10"
                  repeatCount="indefinite"
                  begin="0.2"
                />
              </circle>
              <circle fill="#93c5fd" stroke="none" cx="54" cy="50" r="6">
                <animateTransform
                  attributeName="transform"
                  dur="1s"
                  type="translate"
                  values="0 5 ; 0 -5; 0 5"
                  repeatCount="indefinite"
                  begin="0.3"
                />
              </circle>
            </svg>
          </>
        )}
        {!isLoadingSlots &&
          days.filter((d) => d?.disabled === false).length === 0 && (
            <div className="absolute z-20 flex flex-col px-6 py-4 space-y-2 text-blue-500 transform -translate-x-1/2 -translate-y-1/2 bg-white border-2 border-blue-500 rounded-2xl top-1/2 left-1/2">
              <p className="text-sm text-gray-500">
                <Trans
                  t={t}
                  i18nKey={'datePicker.noAvailabilitiesForPeriod'}
                  components={{
                    bold: (
                      <span className="text-base font-semibold text-gray-700" />
                    ),
                  }}
                  values={{
                    month: inviteeDate().format('MMMM'),
                  }}
                >
                  No availabilities
                </Trans>
              </p>
              <button
                className="flex items-center justify-center space-x-2 hover:underline"
                onClick={incrementMonth}
              >
                <span className="text-sm font-medium">
                  {t('datePicker.tryNextPeriod')}
                </span>
                <ArrowNarrowRightIcon className="w-6 h-6 animate-bounce-right" />
              </button>
            </div>
          )}
        {days.map((day, idx) => {
          return (
            <div
              key={day === null ? `e-${idx}` : `day-${day.date}`}
              style={{
                paddingTop: '100%',
              }}
              className="relative w-full"
              data-cy={`slotDay-${day?.date}`}
            >
              {day === null ? (
                <div key={`e-${idx}`} />
              ) : (
                <button
                  onClick={() =>
                    onDatePicked && onDatePicked(inviteeDate().date(day.date))
                  }
                  disabled={day.disabled}
                  className={classNames(
                    'absolute w-full top-0 left-0 right-0 bottom-0 transition-colors duration-200 rounded-full text-center mx-auto font-semibold',
                    '',
                    date &&
                      date.isSame(inviteeDate().date(day.date), 'day') &&
                      !isLoadingSlots
                      ? 'bg-blue-700 hover:bg-blue-700 text-white'
                      : !day.disabled
                      ? ' bg-blue-50 dark:bg-gray-600'
                      : '',
                    day.disabled
                      ? 'text-gray-400 font-light cursor-default'
                      : 'hover:bg-blue-100 dark:text-white  font-medium ',
                    !day.disabled &&
                      !(
                        date && date.isSame(inviteeDate().date(day.date), 'day')
                      )
                      ? 'text-blue-500'
                      : '',
                    date &&
                      !date.isSame(inviteeDate().date(day.date), 'day') &&
                      !day.disabled
                      ? 'text-blue-500'
                      : '',
                    inviteeDate().date(day.date).isToday() && !isLoadingSlots
                      ? 'border-blue-200 border-2'
                      : '',
                  )}
                >
                  {day.date}
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(DatePicker);
