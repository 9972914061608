/* eslint-disable @typescript-eslint/no-empty-interface */
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { createContext, Fragment, useContext } from 'react';

export const SlideOverPanelContext = createContext<{
  open: boolean;
  setOpen: any;
  title: string;
  setTitle: any;
  content: (setOpen: any) => React.ReactNode;
  contentRef: React.MutableRefObject<HTMLElement | null> | undefined;
  setContentRef: any;
  setContent: any;
}>({
  open: false,
  setOpen: null,
  title: '',
  setTitle: null,
  content: () => void 0,
  contentRef: undefined,
  setContentRef: () => void 0,
  setContent: null,
});

export interface SlideOverPanelProps{}

export function SlideOverPanel(props: SlideOverPanelProps) {
  const { open, setOpen, title, content, contentRef } = useContext(
    SlideOverPanelContext
  );
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-20"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-white">
                        {title}
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-indigo-300">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit
                        aliquam ad hic recusandae soluta.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex-1 py-6 px-4 sm:px-6">
                    {/* Replace with your content */}
                    <div className="absolute inset-0 py-6 px-4 sm:px-6">
                      {/* {content && React.createElement((content as any).type)} */}
                      {contentRef?.current}
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SlideOverPanel;
