import { classNames } from '../../utils';
import { ButtonHTMLAttributes } from 'react';
import { useFormState } from 'react-hook-form';

/* eslint-disable-next-line */
export interface ActionButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  type?: 'submit' | 'button' | 'reset' | undefined;
  fullWitdh?: boolean;
  addStyle?: boolean;
  isBtnDisabled?: boolean;
}

export function ActionButton({
  label = 'Save',
  type = 'submit',
  fullWitdh = false,
  isBtnDisabled = false,
  addStyle,
  ...rest
}: ActionButtonProps) {
  const { isSubmitting } = useFormState();

  return (
    <button
      type={type}
      className={classNames(
        isSubmitting || isBtnDisabled
          ? 'bg-purple-200 cursor-not-allowed'
          : 'bg-purple-700 hover:bg-purple-800',
        fullWitdh ? 'w-full' : 'ml-5',
        addStyle
          ? 'mb-2 md:mb-0 shadow-sm tracking-wider hover:shadow-lg hover:bg-gray-100'
          : '',
        'border border-transparent transition-colors duration-500 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500',
      )}
      disabled={isSubmitting || isBtnDisabled}
      {...rest}
      data-cy="submitBtn"
    >
      {isSubmitting && (
        <div className="flex items-center justify-center">
          <svg
            className="w-5 h-5 mr-3 -ml-1 text-purple-500 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
      {label}
    </button>
  );
}

export default ActionButton;
