/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import Form from '../Form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormTextArea from '../FormTextArea';


export interface ModalComponentProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  tile: string;
  onSubmit: (value: any) => void;
}

export default function ModalComponent({
  isOpen,
  onClose,
  tile,
  onSubmit,
}: ModalComponentProps) {
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation();
  const handleSubmit = (value: string) => {
    onSubmit(value);
    onClose(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full">
                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                  <div className="w-full sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="flex items-center">
                        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-yellow-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="w-6 h-6"
                            aria-hidden="true"
                          />
                        </div>
                        <Dialog.Title
                          as="h3"
                          className="ml-3 text-lg font-medium leading-6 text-gray-900"
                        >
                          {tile}
                        </Dialog.Title>
                      </div>
                      <div className="w-full mt-2">
                        <Form<{ rescheduleReason: string }>
                          onSubmit={(data) =>
                            handleSubmit(data.rescheduleReason)
                          }
                          onCancel={() => onClose(false)}
                          submitButtonLabel={t('appointmentGuestInfos.modalSubmitBtn')}
                          cancelButtonLabel={t('appointmentGuestInfos.modalCancelBtn')}
                          form={{
                            resolver: yupResolver(
                              yup.object().shape({
                                rescheduleReason: yup
                                  .string().optional(),
                              }),
                            ),
                            defaultValues: { rescheduleReason: '' },
                          }}
                        >
                          <FormTextArea
                            name="rescheduleReason"
                            label={t('appointmentGuestInfos.addReasonLabel')}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
