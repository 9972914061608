/* eslint-disable prefer-spread */
import { classNames } from '../../utils';
import './loading-skeleton.module.scss';

/* eslint-disable-next-line */
export interface LoadingSkeletonProps {}

export function LoadingSkeleton(props: LoadingSkeletonProps) {
  return (
    <div className="flex flex-col items-center justify-center h-screen max-w-2xl mx-auto my-8 space-y-2">
      {/* Header */}
      <div className="flex flex-row w-full px-8 my-4 space-x-3 h-36 animate-pulse">
        <div className="flex-grow h-full bg-gray-300 rounded-md">
          <p className="flex items-center justify-center text-5xl font-bold text-gray-400 uppercase py-14">
            Yalendar
          </p>
        </div>
      </div>
      {/* Body */}
      <div className="flex flex-col w-full py-4 md:flex-row h-4/5 animate-pulse">
        {/* Right */}
        <div className="flex flex-col w-full h-full">
          <div className="w-2/3 h-5 mx-auto bg-gray-400 rounded-md"></div>
          <div className="grid w-4/5 grid-cols-5 gap-2 mx-auto mt-16 md:w-4/5 md:grid-cols-6 lg:grid-cols-7 h-96 ">
            {Array.from({ length: 30 }, (_, i) => (
              <div
                key={i}
                className={classNames(
                  [8, 9, 10, 18, 19, 22, 23, 24].includes(i)
                    ? 'animate-bounce'
                    : 'animate-pulse',
                )}
              >
                <div
                  className={classNames(
                    [8, 9, 10, 18, 19, 22, 23, 24].includes(i)
                      ? 'bg-blue-400'
                      : ' bg-gray-400 ',
                    'w-12 h-12 rounded-full transform transition duration-500 ease-in-out hover:scale-150',
                  )}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingSkeleton;
