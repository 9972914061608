import { classNames, unwindObject } from '../../utils';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup } from '@headlessui/react';
import './color-selection.module.scss';

/* eslint-disable-next-line */
export interface ColorSelectionProps {
  name: string;
  initialColor?: string;
  label?: string;
  optional?: boolean;
}
export const COLOR_SELECTION_AVAILABLE_COLORS = [
  'FF4F00',
  '8B5CF6',
  'EC4899',
  'CCF000',
  '0099FF',
  '3B82F6',
  '10B981',
  '047857',
];
export function ColorSelection({
  name,
  label,
  optional,
}: ColorSelectionProps) {
  const methods = useFormContext();
  const errorName = unwindObject(methods.formState.errors, name);
  return (
    <Controller
      control={methods.control}
      name={name}
      render={({ field }) => (
        <div className="my-4">
          <RadioGroup
            value={field.value}
            onChange={(value) => field.onChange(value)}
          >
            <RadioGroup.Label
              className={classNames(optional ? 'flex justify-between' : '')}
            >
              {label && (
                <label
                  htmlFor={name}
                  className="block text-sm font-medium text-gray-700"
                >
                  {label}
                </label>
              )}
              {optional && (
                <span className="text-sm text-gray-500">Optional</span>
              )}
            </RadioGroup.Label>
            <div className="flex items-center mt-2 space-x-3">
              {COLOR_SELECTION_AVAILABLE_COLORS.map((color, index) => (
                <RadioGroup.Option
                  key={index}
                  value={color}
                  data-cy={`calColor-${index}`}
                  className={({ active, checked }) =>
                    classNames(
                      active && checked ? 'ring ring-offset-1' : '',
                      !active && checked ? 'ring-2' : '',
                      '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none',
                    )
                  }
                >
                  <RadioGroup.Label as="p" className="sr-only">
                    {color}
                  </RadioGroup.Label>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      'h-8 w-8 border border-black border-opacity-10 rounded-full',
                    )}
                    style={{
                      backgroundColor: `#${color}`,
                    }}
                  />
                </RadioGroup.Option>
              ))}
            </div>
            {errorName && (
              <p className={classNames('text-sm text-red-600 mt-2')}>
                {errorName.message}
              </p>
            )}
          </RadioGroup>
        </div>
      )}
    ></Controller>
  );
}

export default ColorSelection;
