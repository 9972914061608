import { useEffect } from 'react';

var AxeptioInjector = function AxeptioInjector(_ref) {
  var axeptioClientId = _ref.axeptioClientId;
  useEffect(function () {
    if (axeptioClientId) {
      var el = document.createElement('script');
      el.setAttribute('src', 'https://static.axept.io/sdk-slim.js');
      el.setAttribute('type', 'text/javascript');
      el.setAttribute('async', 'true');
      el.setAttribute('data-id', axeptioClientId || '');

      if (document.body !== null) {
        document.body.appendChild(el);
      }
    }
  }, [axeptioClientId]);
  return null;
};

export default AxeptioInjector;