import { TimeSlotInterface, SlotInterface } from '../AppointmentWidget';
import { classNames } from '../../utils';
import { useTranslation } from 'react-i18next';

export interface TimeSlotButtonProps {
  timeSlots?: TimeSlotInterface;
  onSlotSelected: (slot: SlotInterface) => void;
  isDiagnostic?: boolean;
  showRemainingSpot?: boolean;
}
export function TimeSlotButton({
  timeSlots,
  onSlotSelected,
  isDiagnostic = false,
  showRemainingSpot = false,
}: TimeSlotButtonProps) {
  const { t } = useTranslation();
  const handleClick = (slot: SlotInterface) => {
    return onSlotSelected(slot);
  };
  return (
    <div
      className="px-1 mt-4 overflow-scroll md:overflow-scroll scrollbar-hide"
      style={{ maxHeight: '480px' }}
    >
      {timeSlots?.slots && timeSlots?.slots.length > 0 ? (
        timeSlots?.slots
          .filter((slot) => (isDiagnostic ? true : slot.status === 'available'))
          .map((slot: SlotInterface, index: number) => (
            <button
              key={index}
              type="button"
              style={{
                paddingTop: '14px',
                marginTop: '12px',
              }}
              className={classNames(
                isDiagnostic && slot.status === 'unavailable'
                  ? 'border-dashed border-gray-300 hover:border-gray-700 text-gray-600 flex-col items-center cursor-default'
                  : 'hover:bg-blue-500 text-blue-700 border-blue-500 hover:border-transparent hover:text-white',
                showRemainingSpot && slot.status === 'available'
                  ? 'flex-col items-center'
                  : '',
                (isDiagnostic && slot.status === 'unavailable') ||
                  (showRemainingSpot && slot.status === 'available')
                  ? ''
                  : 'h-12',
                'bg-transparent   transition-colors duration-300 font-semibold  py-2 px-4 border rounded w-full',
                'border border-transparent rounded-md shadow-sm py-2 px-4 flex justify-center text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-700',
              )}
              onClick={() => slot.status === 'available' && handleClick(slot)}
              data-cy={`slot-${index}`}
            >
              <div>{slot.startTime}</div>
              {slot.status === 'available' && showRemainingSpot && (
                <div className="flex justify-center">
                  <span className="text-sm font-light">
                    {t(`appointmentWidget.remainingSpot`, {
                      nbSpot: slot.remainingSpot,
                    })}
                  </span>
                </div>
              )}
              {isDiagnostic &&
                slot.status === 'unavailable' &&
                slot.conflicts &&
                slot.conflicts.length > 0 && (
                  <div className="flex flex-col space-y-1">
                    <span className="text-xs font-normal text-red-500">
                      {t(`appointmentWidget.${slot.status}`)}
                    </span>
                    <div className="flex justify-center space-x-2">
                      {slot.conflicts.map((conflict, conflictIdx) => (
                        <span
                          key={conflictIdx}
                          className="py-0.5 px-1 text-xs font-medium text-gray-500 uppercase border border-gray-300 rounded bg-gray-50"
                        >
                          {t(`appointmentWidget.${conflict.code}`)}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
            </button>
          ))
      ) : (
        <div className="mt-3 text-center text-gray-400">No slots available</div>
      )}
    </div>
  );
}

export default TimeSlotButton;
