import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export interface PhoneInputProps {
  onPhoneChange?: (phone: string) => void;
  phone?: string;
  isValid?: boolean;
}

export const PhoneNumberInput = ({
  phone,
  onPhoneChange,
  isValid,
}: PhoneInputProps) => {
  const [value, setValue] = useState<string | undefined | null>(phone);
  const handleChange = (phone: string) => {
    setValue(phone);
    onPhoneChange && onPhoneChange(phone);
  };
  return (
    <PhoneInput
      inputStyle={{ width: '100%', height: '40px' }}
      country={Intl.DateTimeFormat().resolvedOptions().locale || 'us'}
      value={value}
      onChange={(phone) => handleChange(phone)}
      enableSearch={true}
      disableSearchIcon={true}
      autocompleteSearch={true}
      isValid={isValid}
    />
  );
};
