var seoImages = {
  "default": 'https://kreezus.com/wp-content/uploads/2022/01/logo-avatar-scaled.jpg',
  ogImage: 'https://yalendar-og-image.vercel.app/'
};
export var getSeoImage = function getSeoImage(key) {
  return seoImages[key];
};
export var seoConfig = {
  headSeo: {
    siteName: 'Yalendar.com'
  },
  defaultNextSeo: {
    twitter: {
      handle: '@yalendar',
      site: '@Yalendar',
      cardType: 'summary_large_image'
    }
  }
};