import moment from 'moment';
import { MomentUtils } from '../..';

interface TimeInterval {
  startTime: string;
  endTime: string;
}
interface Error {
  key: number;
  message: string;
}

export class TimeIntervalUtils {
  /**
   * @param  {string} firstStartTime
   * @param  {string} firstEndTime
   */
  static checkIfintervalsAreIdenticals = function (
    firstStartTime: string,
    firstEndTime: string,
  ) {
    const momentDefaultUnitOfTime = 'minutes';
    const convertFirstStartTimeIntoDate =
      MomentUtils.fromTimeToHoursAndMinutes(firstStartTime);
    const convertFirstEndTimeIntoDate =
      MomentUtils.fromTimeToHoursAndMinutes(firstEndTime);
    /**
     * @param  {string} secondStartTime
     * @param  {string} secondEndTime
     */
    return {
      /**
       * @param  {string} secondStartTime
       * @param  {string} secondEndTime
       */
      isIdenticalTo: (secondStartTime: string, secondEndTime: string) => {
        const convertSecondStartTimeIntoDate =
          MomentUtils.fromTimeToHoursAndMinutes(secondStartTime);
        const convertSecondEndTimeIntoDate =
          MomentUtils.fromTimeToHoursAndMinutes(secondEndTime);
        return (
          moment(convertFirstStartTimeIntoDate).isSame(
            convertSecondStartTimeIntoDate,
            momentDefaultUnitOfTime,
          ) &&
          moment(convertFirstEndTimeIntoDate).isSame(
            convertSecondEndTimeIntoDate,
            momentDefaultUnitOfTime,
          )
        );
      },
    };
  };

  /**
   * @param  {string} startTimeInDatabase
   * @param  {string} endTimeInDatabase
   */
  static CheckIfStartAndEndTimeOverlapWithAnother = function (
    firstStartTime: string,
    firstEndTime: string,
  ) {
    return {
      /**
       * @param  {string} secondStartTime
       * @param  {string} secondEndTime
       */
      isBetween: (secondStartTime: string, secondEndTime: string) => {
        return (
          MomentUtils.startTimeIsBetweenStartAndEnTimeOfInterval(
            firstStartTime,
            secondStartTime,
            secondEndTime,
          ) ||
          MomentUtils.endTimeIsBetweenStartAndEnTimeOfInterval(
            firstEndTime,
            secondStartTime,
            secondEndTime,
          ) ||
          MomentUtils.startAndEndTimeIsBetweenStartAndEnTimeOfOfInterval(
            firstStartTime,
            firstEndTime,
            secondStartTime,
            secondEndTime,
          ) ||
          MomentUtils.startTimeAndEndTimeIsBetweenStartAndEnTimeOfInterval(
            firstStartTime,
            firstEndTime,
            secondStartTime,
            secondEndTime,
          )
        );
      },
    };
  };

  static CheckIfStartTimeComesAfterTheEndTime(
    startTime: string,
    endTime: string,
  ) {
    const convertStartTime = MomentUtils.fromTimeToHoursAndMinutes(startTime);
    const convertEndTime = MomentUtils.fromTimeToHoursAndMinutes(endTime);
    if (convertEndTime.isSameOrBefore(convertStartTime, 'minutes'))
      return 'Please make sure the endTime comes after the startTime';
    else return;
  }

  static validateAvailabilitiesStartTimeAndEndTime(intervals: TimeInterval[]) {
    const errors: Error[] = [];
    intervals.forEach((timeInterval, index) => {
      TimeIntervalUtils.CheckIfStartTimeComesAfterTheEndTime(
        timeInterval.startTime,
        timeInterval.endTime,
      );
      const convertStartTime = MomentUtils.fromTimeToHoursAndMinutes(
        timeInterval.startTime,
      );
      const convertEndTime = MomentUtils.fromTimeToHoursAndMinutes(
        timeInterval.endTime,
      );
      if (convertEndTime.isSameOrBefore(convertStartTime, 'minutes')) {
        errors[index] = {
          key: index,
          message: 'Please make sure the endTime comes after the startTime',
        };
      } else {
        const errorRes = TimeIntervalUtils.checkIfAvailabilityOverlaps(
          intervals,
          timeInterval,
        );
        errors[index] = {
          key: index,
          message: errorRes,
        };
      }
    });
    return errors;
  }

  static checkIfAvailabilityOverlaps(
    intervals: TimeInterval[],
    timeIntervalToChecked: TimeInterval,
  ) {
    const copyInterval = [...intervals].filter(
      (intervalFiltered) => timeIntervalToChecked !== intervalFiltered,
    );
    for (const interval of copyInterval) {
      if (
        TimeIntervalUtils.checkIfintervalsAreIdenticals(
          interval.startTime,
          interval.endTime,
        ).isIdenticalTo(
          timeIntervalToChecked.startTime,
          timeIntervalToChecked.endTime,
        )
      )
        return 'The start and endTime are same';
      else if (
        TimeIntervalUtils.CheckIfStartAndEndTimeOverlapWithAnother(
          interval.startTime,
          interval.endTime,
        ).isBetween(
          timeIntervalToChecked.startTime,
          timeIntervalToChecked.endTime,
        )
      ) {
        return 'The availability overlaps';
      }
    }
    return '';
  }
}
