import moment from 'moment';
import { TimeInterval } from '../..';
/**
 * This class has static functions which allow to parse the time.
 * @MomentTimeParse
 */
export class MomentUtils {
  /**
   * @param  {Date} startTime
   * @param  {number} number
   * @param  {moment.unitOfTime.DurationConstructor|undefined} unit?
   */
  static add(
    startTime: Date,
    number: number,
    unit?: moment.unitOfTime.DurationConstructor | undefined,
  ) {
    return moment(startTime).add(number, unit);
  }

  static substract(
    startTime: Date,
    number: number,
    unit?: moment.unitOfTime.DurationConstructor | undefined,
  ) {
    return moment(startTime).subtract(number, unit);
  }

  /*
   * @param timeSlot
   * @returns
   */
  static fromTimeToHoursAndMinutesInUtcFormat(timeSlot: Date) {
    return moment(timeSlot).utc().format('HH:mm');
  }
  static setTime(date: string, time: string) {
    return moment(date + ' ' + time).toDate();
  }

  /**
   * This function allows to parse the time in hours and minutes.
   * @param timeSlot
   * @returns
   */
  static fromTimeToHoursAndMinutes(timeSlot: string) {
    return moment(timeSlot, 'HH:mm');
  }

  static formatDateToDDMMYYY(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  static isBefore(
    startTime: Date,
    endTime: Date,
    unit?: moment.unitOfTime.StartOf | undefined,
  ) {
    if (moment(startTime).isBefore(endTime, unit)) return true;
    else return false;
  }
  /**
   * @param  {Date} timeSlot
   */
  static fromTimeToHoursAndMinutesFormat(timeSlot: Date) {
    return moment(timeSlot).format('HH:mm');
  }

  static getRandomHours = (timeIntervals: TimeInterval[]) => {
    const latestEndTime = MomentUtils.fromTimeToHoursAndMinutes(
      timeIntervals[timeIntervals.length - 1].endTime,
    );
    const latestEndTimeConverted = MomentUtils.add(
      latestEndTime.toDate(),
      latestEndTime.utcOffset(),
      'minutes',
    ).toDate();
    return {
      startTime: MomentUtils.add(latestEndTimeConverted, 0, 'hours')
        .utc()
        .format('HH:mm'),
      endTime: MomentUtils.add(latestEndTimeConverted, 2, 'hours')
        .utc()
        .format('HH:mm')
        .valueOf(),
    };
  };

  static startTimeIsBetweenStartAndEnTimeOfInterval = function (
    startTimeInDatabase: string,
    startTimeOfClient: string,
    endTimeOfClient: string,
  ) {
    const momentDefaultUnitOfTime = 'minutes';
    const convertStartTimeOfClient =
      MomentUtils.fromTimeToHoursAndMinutes(startTimeOfClient);
    const convertStartTimeInDatabase =
      MomentUtils.fromTimeToHoursAndMinutes(startTimeInDatabase);
    const convertEndTimeOfClient =
      MomentUtils.fromTimeToHoursAndMinutes(endTimeOfClient);
    return (
      convertStartTimeOfClient.isSameOrBefore(
        convertStartTimeInDatabase.toDate(),
        momentDefaultUnitOfTime,
      ) &&
      convertEndTimeOfClient.isAfter(
        convertStartTimeInDatabase.toDate(),
        momentDefaultUnitOfTime,
      )
    );
  };

  static endTimeIsBetweenStartAndEnTimeOfInterval = function (
    endTimeInDatabase: string,
    startTimeOfClient: string,
    endTimeOfClient: string,
  ) {
    const momentDefaultUnitOfTime = 'minutes';
    const convertStartTimeOfClient =
      MomentUtils.fromTimeToHoursAndMinutes(startTimeOfClient);
    const convertEndTimeInDatabase =
      MomentUtils.fromTimeToHoursAndMinutes(endTimeInDatabase);
    const convertEndTimeOfClient =
      MomentUtils.fromTimeToHoursAndMinutes(endTimeOfClient);
    return (
      convertStartTimeOfClient.isBefore(
        convertEndTimeInDatabase.toDate(),
        momentDefaultUnitOfTime,
      ) &&
      convertEndTimeOfClient.isAfter(
        convertEndTimeInDatabase.toDate(),
        momentDefaultUnitOfTime,
      )
    );
  };

  static startAndEndTimeIsBetweenStartAndEnTimeOfOfInterval = function (
    startTimeInDatabase: string,
    endTimeInDatabase: string,
    startTimeOfClient: string,
    endTimeOfClient: string,
  ) {
    const momentDefaultUnitOfTime = 'minutes';
    const convertStartTimeOfClient =
      MomentUtils.fromTimeToHoursAndMinutes(startTimeOfClient);
    const convertEndTimeInDatabase =
      MomentUtils.fromTimeToHoursAndMinutes(endTimeInDatabase);
    const convertEndTimeOfClient =
      MomentUtils.fromTimeToHoursAndMinutes(endTimeOfClient);
    const convertStartTimeInDatabase =
      MomentUtils.fromTimeToHoursAndMinutes(startTimeInDatabase);
    return (
      convertStartTimeOfClient.isBefore(
        convertStartTimeInDatabase.toDate(),
        momentDefaultUnitOfTime,
      ) &&
      convertEndTimeOfClient.isAfter(
        convertEndTimeInDatabase.toDate(),
        momentDefaultUnitOfTime,
      )
    );
  };

  static startTimeAndEndTimeIsBetweenStartAndEnTimeOfInterval = function (
    startTimeInDatabase: string,
    endTimeInDatabase: string,
    startTimeOfClient: string,
    endTimeOfClient: string,
  ) {
    const momentDefaultUnitOfTime = 'minutes';
    const convertStartTimeOfClient =
      MomentUtils.fromTimeToHoursAndMinutes(startTimeOfClient);
    const convertStartTimeInDatabase =
      MomentUtils.fromTimeToHoursAndMinutes(startTimeInDatabase);
    const convertEndTimeInDatabase =
      MomentUtils.fromTimeToHoursAndMinutes(endTimeInDatabase);
    const convertEndTimeOfClient =
      MomentUtils.fromTimeToHoursAndMinutes(endTimeOfClient);
    return (
      convertStartTimeOfClient.isSameOrAfter(
        convertStartTimeInDatabase.toDate(),
        momentDefaultUnitOfTime,
      ) &&
      convertEndTimeOfClient.isSameOrBefore(
        convertEndTimeInDatabase.toDate(),
        momentDefaultUnitOfTime,
      )
    );
  };
}
