import React, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../Form';
import FormInput from '../FormInput';
import FormTextArea from '../FormTextArea';
import FormRadio from '../FormRadio';
import { useTranslation } from 'react-i18next';
import {
  LocationMarkerIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
  CheckCircleIcon,
  FolderAddIcon,
} from '@heroicons/react/solid';
import { PhoneNumberInput } from '../PhoneInput';
import {
  CalendarTypeCustomInput,
  CalendarTypeCustomInputType,
} from '@prisma/client';
import { Controller, UseFormReturn } from 'react-hook-form';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '../../utils';
import { FormCheckToggle } from '../FormCheckToggle';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { AppointmentEntity, Person } from '@yalendar/api-interfaces';
import { ExclamationIcon } from '@heroicons/react/outline';
import ModalComponent from '../Modal/Modal';

export const getCalendarIntegrationImage = (integrationType: string) => {
  switch (integrationType) {
    case 'google_calendar':
      return 'integrations/google-calendar.svg';
    case 'office365_calendar':
      return 'integrations/outlook.svg';
    case 'caldav_calendar':
      return 'integrations/caldav.svg';
    case 'apple_calendar':
      return 'integrations/apple-calendar.svg';
    case 'zoom_video':
      return 'integrations/zoom.svg';
    default:
      return '';
  }
};
export const getCalendarIntegrationName = (integrationType: string) => {
  switch (integrationType) {
    case 'google_calendar':
      return 'Google Calendar';
    case 'office365_calendar':
      return 'Office 365 / Outlook.com Calendar';
    case 'caldav_calendar':
      return 'CalDav Server';
    case 'apple_calendar':
      return 'Apple Calendar';
    case 'zoom_video':
      return 'Zoom';
    default:
      return '';
  }
};

export interface GuestResponse {
  label: string;
  type: CalendarTypeCustomInputType;
  answers: any;
}
export interface TimeSlotEntity {
  startTime: string;
  endTime: string;
}
export interface AppointmentGuestInfosProps {
  defaultValue?: GuestInfos;
  onGuestInfosSubmit: (data: GuestInfos) => Promise<void>;
  onCancel?: () => void;
  locationValue?: (event: any) => void;
  calendarLocations?: { type: string; name: string; description?: string }[];
  calendarCustomInputs: CalendarTypeCustomInput[];
  disableGuests: boolean;
  previewMode?: boolean;
  onFileUpload?: (file: any) => string;
  appointmentToUpdate?: AppointmentEntity;
}
export interface GuestInfos {
  guestName: string;
  guestEmail: string;
  location: string;
  locationDescription?: string;
  additionalInfos: GuestResponse[];
  guests: string[];
  rescheduleReason?: string;
}
const phone = 'phone';
const googleMeet = 'google_calendar';
export const AppointmentGuestInfos = ({
  defaultValue,
  onGuestInfosSubmit,
  onCancel = () => void 0,
  calendarLocations = [],
  calendarCustomInputs = [],
  disableGuests = false,
  previewMode = false,
  onFileUpload,
  appointmentToUpdate
}: AppointmentGuestInfosProps) => {
  const { t } = useTranslation();
  const [formReturn, setFormReturn] = useState<
    UseFormReturn<
      GuestInfos & {
        firstname: string;
        lastname: string;
      }
    >
  >();
  const [showGuestInput, setShowGuestInput] = useState<boolean>(false);
  const [inputFiles, setInputFiles] = useState<string[]>([]);
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [onCanceled, setOnCanceled] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<GuestInfos & {
    firstname: string;
    lastname: string;
  }>()
  const onSubmitAppointment = useCallback(
    async (data: GuestInfos) => {
      if (!previewMode) {
        // Override location with locationDescription in any case
        data.location =
          data.location === 'inPerson'
            ? `inPerson:${
                calendarLocations?.find((loc) => loc.type === 'inPerson')
                  ?.description
              }`
            : data.locationDescription || data.location;
        delete data.locationDescription;
        return onGuestInfosSubmit({
          ...data,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onGuestInfosSubmit, calendarLocations],
  );

  const handleSubmitAppointment = (rescheduleReason?: string) => {
    if (rescheduleReason) {
      if (data) {
        data.additionalInfos.forEach((item, index) => {
          if (item.type === 'FILE') {
            item.answers = inputFiles[index];
          }
        });
        data.rescheduleReason = rescheduleReason;
        onSubmitAppointment(data);
      }
    } 
    if(onCanceled){
      if (data) {
        data.additionalInfos.forEach((item, index) => {
          if (item.type === 'FILE') {
            item.answers = inputFiles[index];
          }
        });
        onSubmitAppointment(data);
      }
    }
  };

  useEffect(() => {
    if (formReturn) {
      const watchSlugSubscription = formReturn.watch((value: any, { name }) => {
        if (name === 'guestName' && value.guestName?.length > 0) {
          const fullNameSplitted = value.guestName.split(' ');
          formReturn.setValue('firstname', fullNameSplitted[0]);
          fullNameSplitted.length > 1 &&
            formReturn.setValue('lastname', fullNameSplitted[1]);
        }
        return void 0;
      });
      return () => watchSlugSubscription.unsubscribe();
    }
    return undefined;
  }, [formReturn]);

  const onFileChanged = async (e: any, index: number) => {
    setIsUploadingFile(true);
    const file = e.target.files[0];
    if (file && onFileUpload) {
      const fileUrl: string = await onFileUpload(file);
      const copy = [...inputFiles];
      if (!copy[index]) copy[index] = '';
      copy[index] = fileUrl;
      setInputFiles(copy);
      setIsUploadingFile(false);
    }
  };
  return (
    <div className="w-full">
      <Form<
        GuestInfos & {
          firstname: string;
          lastname: string;
        }
      >
        formId="bookAppointment"
        onSubmit={(data) => {
          if (appointmentToUpdate) {
            setOpenModal(true);
            setData(data);
          } else {
            data.additionalInfos.forEach((item, index) => {
              if (item.type === 'FILE') {
                item.answers = inputFiles[index];
              }
            });
            onSubmitAppointment(data);
          }
        }}
        formReturnAware={setFormReturn}
        useCustomFooter={previewMode}
        onCancel={onCancel}
        form={{
          //mode: 'all',
          resolver: yupResolver(
            yup.object().shape({
              guestName: yup
                .string()
                .required(t('appointmentGuestInfos.nameRequired')),
              guestEmail: yup
                .string()
                .email(t('common.emailInvalid'))
                .required(t('appointmentGuestInfos.emailRequired')),
              location: yup.string().when('guestName', {
                is: (guestName: string) => calendarLocations.length > 0,
                then: yup
                  .string()
                  .required(t('appointmentGuestInfos.locationRequired')),
                otherwise: yup.string().optional(),
              }),
              locationDescription: yup.string().when('location', {
                is: (loc: string) => ['phone'].includes(loc),
                then: yup
                  .string()
                  .required(t('appointmentGuestInfos.mandatoryInfo')),
                otherwise: yup.string().nullable(),
              }),
              guests: yup.array(yup.string().email()),
              additionalInfos: yup
                .array(
                  yup.object().shape({
                    label: yup.string(),
                    type: yup.string(),
                    required: yup.boolean(),
                    answers: yup.string().when('required', {
                      is: (val: boolean | any) => val,
                      then: yup
                        .string()
                        .required(
                          t('appointmentGuestInfos.additionalInputsIsRequired'),
                        ),
                      otherwise: yup.string().nullable(),
                    }),
                  }),
                )
                .length(calendarCustomInputs.length),
            }),
          ),
          defaultValues: {
            guestName: defaultValue?.guestName || '',
            guestEmail: defaultValue?.guestEmail || '',
            location:
              (defaultValue && defaultValue.location) ??
              (calendarLocations?.length === 1
                ? calendarLocations[0].type
                : undefined),
            locationDescription:
              defaultValue?.locationDescription &&
              defaultValue.locationDescription,
            guests: (defaultValue && defaultValue?.guests) || [],
            additionalInfos: calendarCustomInputs.map((customInput, index) => {
              const addInfo = {
                type: customInput.type,
                label: customInput.label,
                required: customInput.required,
                answers: [
                  'TEXT',
                  'NUMBER',
                  'TEXTLONG',
                  'RADIO',
                  'FILE',
                ].includes(customInput.type)
                  ? ''
                  : ['CHECKBOX'].includes(customInput.type)
                  ? false
                  : ['FILE'].includes(customInput.type)
                  ? inputFiles[index]
                  : '',
              };
              return addInfo;
            }),
          },
        }}
        submitButtonLabel={t('appointmentGuestInfos.submitBtn')}
        cancelButtonLabel={t('appointmentGuestInfos.cancelBtn')}
        isSubmitBtnDisabled={isUploadingFile}
      >
        <FormInput
          type="text"
          name="guestName"
          label={t('appointmentGuestInfos.name')}
          tooltip={t('appointmentGuestInfos.nameHelpLabel')}
          condensed
        />
        <FormInput name="firstname" type="hidden" />
        <FormInput name="lastname" type="hidden" />
        <FormInput
          type="text"
          name="guestEmail"
          label={t('appointmentGuestInfos.email')}
          tooltip={t('appointmentGuestInfos.emailHelpLabel')}
          condensed
        />
        <Controller
          control={formReturn?.control}
          name="location"
          render={({ field, formState }) =>
            calendarLocations && calendarLocations.length > 0 ? (
              <RadioGroup
                value={field.value}
                onChange={(evt) => {
                  field.onChange(evt);
                }}
              >
                <RadioGroup.Label className="block text-sm font-medium text-gray-700">
                  {t('appointmentGuestInfos.locationLabel')}
                </RadioGroup.Label>

                <div
                  className={classNames(
                    formState.errors.location ? 'border-red-500' : '',
                    'flex flex-col mt-1 space-y-2 p-1 border border-transparent rounded-md',
                  )}
                >
                  {calendarLocations.map((calLocation, calLocationIdx) => (
                    <RadioGroup.Option
                      key={calLocationIdx}
                      value={calLocation.type}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? 'border-transparent' : 'border-gray-300',
                          active ? 'ring-2 ring-indigo-500' : '',
                          'relative bg-white border hover:border-indigo-500 transition-colors rounded-lg shadow-sm pl-2 pr-4 py-4 flex cursor-pointer focus:outline-none',
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <div className="flex w-full space-x-3">
                            <div className="flex flex-col justify-center">
                              {calLocation.type === 'phone' && (
                                <PhoneIncomingIcon className="w-6 h-6 text-blue-700" />
                              )}
                              {calLocation.type === 'phoneInbound' && (
                                <PhoneOutgoingIcon className="w-6 h-6 text-blue-700" />
                              )}
                              {calLocation.type === 'inPerson' && (
                                <LocationMarkerIcon className="w-6 h-6 text-pink-500" />
                              )}
                              {calLocation.type === 'google_calendar' && (
                                <img
                                  src="https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"
                                  alt="Google meet"
                                  className="w-6 h-6"
                                />
                              )}
                              {calLocation.type === 'zoom_video' && (
                                <img
                                  src={`/${getCalendarIntegrationImage(
                                    'zoom_video',
                                  )}`}
                                  alt="Zoom.us"
                                  className="w-6 h-6"
                                />
                              )}
                            </div>
                            <div className="flex flex-col w-full space-y">
                              <span className="text-sm font-semibold text-gray-500">
                                {t(`chooseLocation.guest-${calLocation.type}`)}
                              </span>
                              {['inPerson', 'phoneInbound'].includes(
                                calLocation.type,
                              ) && (
                                <span className="text-sm text-gray-400">
                                  {calLocation.description}
                                </span>
                              )}
                              {checked && calLocation.type === 'phone' && (
                                <Controller
                                  control={formReturn?.control}
                                  defaultValue={undefined}
                                  name="locationDescription"
                                  render={({ field }) => (
                                    <>
                                      <div
                                        className={classNames(
                                          formState.errors.locationDescription
                                            ? 'border-red-500'
                                            : '',
                                          'mt-2 border rounded-sm w-full',
                                        )}
                                      >
                                        <PhoneNumberInput
                                          onPhoneChange={(phone) =>
                                            field.onChange(`phone:${phone}`)
                                          }
                                          data-cy={'phoneNumber'}
                                        />
                                      </div>
                                      {formState.errors.locationDescription && (
                                        <p className="px-2 mt-1 text-sm text-red-600">
                                          {
                                            formState.errors.locationDescription
                                              .message
                                          }
                                        </p>
                                      )}
                                    </>
                                  )}
                                />
                              )}
                            </div>
                          </div>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? 'invisible' : '',
                              'h-5 w-5 text-indigo-600 absolute right-3 top-2',
                            )}
                            aria-hidden="true"
                          />
                          <div
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked
                                ? 'border-indigo-500'
                                : 'border-transparent',
                              'absolute -inset-px rounded-lg pointer-events-none',
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
                {formState.errors.location && (
                  <p className="px-2 mt-1 text-sm text-red-600">
                    {formState.errors.location.message}
                  </p>
                )}
              </RadioGroup>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )
          }
        />
        {!showGuestInput && (!disableGuests as any) && (
          <button
            className="text-sm font-medium text-blue-500 transition-colors duration-500 hover:text-blue-700"
            onClick={() => setShowGuestInput(true)}
          >
            {t('appointmentGuestInfos.addGuest')}
          </button>
        )}
        {showGuestInput && (!disableGuests as any) && (
          <Controller
            control={formReturn?.control}
            name="guests"
            render={({ field: { onChange, value } }) => (
              <div className="my-2 space-y-1">
                <h3 className="block text-sm font-medium text-gray-700">
                  {t('appointmentGuestInfos.additionalGuestLabel')}
                </h3>
                <div>
                  <ReactMultiEmail
                    onChange={onChange}
                    emails={value}
                    placeholder="john.doe@example.com"
                    getLabel={(
                      email: string,
                      index: number,
                      removeEmail: (index: number) => void,
                    ) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            )}
          />
        )}
        <Controller
          control={formReturn?.control}
          name="additionalInfos"
          render={({ field, formState }) => (
            <>
              {calendarCustomInputs.map((customInput, customInputIndex) => {
                return ['TEXT', 'NUMBER'].includes(customInput.type) ? (
                  <div key={customInputIndex}>
                    <FormInput
                      label={customInput.label}
                      name={`additionalInfos.${customInputIndex}.answers`}
                      type={customInput.type.toLowerCase()}
                      tooltip={customInput.helpText || ''}
                      placeholder={customInput.placeholder}
                      condensed
                    />
                  </div>
                ) : ['TEXTLONG'].includes(customInput.type) ? (
                  <div key={customInputIndex}>
                    <FormTextArea
                      label={customInput.label}
                      name={`additionalInfos.${customInputIndex}.answers`}
                      tooltip={customInput.helpText || ''}
                      placeholder={customInput.placeholder}
                      condensed
                    />
                  </div>
                ) : ['RADIO'].includes(customInput.type) ? (
                  <div key={customInputIndex}>
                    <FormRadio
                      label={customInput.label}
                      tooltip={customInput.helpText || ''}
                      name={`additionalInfos.${customInputIndex}.answers`}
                      options={(
                        (customInput.options as any).items as any[]
                      ).map((opt) => {
                        return { name: opt, value: opt };
                      })}
                    />
                  </div>
                ) : ['CHECKBOX'].includes(customInput.type) ? (
                  <div key={customInputIndex}>
                    <FormCheckToggle
                      condensed
                      label={customInput.label}
                      name={`additionalInfos.${customInputIndex}.answers`}
                      description={customInput.helpText || ''}
                    />
                  </div>
                ) : ['FILE'].includes(customInput.type) ? (
                  <div key={customInputIndex}>
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      htmlFor="onFileUpload"
                    >
                      {customInput.label}
                    </label>
                    <FormInput
                      disabled={isUploadingFile}
                      type={customInput.type.toLowerCase()}
                      name={`additionalInfos.${customInputIndex}.answers`}
                      onChange={(e) => onFileChanged(e, customInputIndex)}
                    />
                  </div>
                ) : (
                  ''
                );
              })}
            </>
          )}
        />
        {previewMode && (
          <div className="flex items-center justify-between w-full p-4 text-yellow-600 border border-yellow-600 rounded-md shadow-sm bg-yellow-50">
            <ExclamationIcon className="w-6 h-6" />
            <p className="text-base font-semibold">
              Cannot book in preview mode
            </p>
            <button
              className="text-yellow-700 hover:underline"
              onClick={() => onCancel && onCancel()}
            >
              Go Back
            </button>
          </div>
        )}
      </Form>
      <ModalComponent
        tile={t('appointmentGuestInfos.addReason')}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
          setOnCanceled(true)
          handleSubmitAppointment();
        }}
        onSubmit={(rescheduleReason) =>
          handleSubmitAppointment(rescheduleReason)
        }
      />
    </div>
  );
};
