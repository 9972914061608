import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const htmlToDraft = typeof window === 'object' && require('html-to-draftjs').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Editor = typeof window === 'object' && require('react-draft-wysiwyg').Editor;
export function WYSIWYGEditor({
  onChange,
  value,
  editorClass = 'h-48 px-2 border border-gray-300  shadow-sm rounded-md',
  defaultFontSize = 14,
  mentionsSuggestions = [],
  onFileUpload,
}: {
  onChange: (...event: any[]) => void;
  value: string;
  editorClass?: string;
  defaultFontSize?: number;
  mentionsSuggestions?: {
    text: string;
    value: string;
  }[];
  onFileUpload?: (file: any) => void
}): JSX.Element {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : '';
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap,
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [updated, value]);

  const onEditorStateChange = (editorState: EditorState) => {
    setUpdated(true);
    setEditorState(editorState);

    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <div className="editor">
      <Editor
        spellCheck
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        mention={{
          separator: ' ',
          trigger: '@',
          suggestions: mentionsSuggestions,
        }}
        hashtag={{
          separator: ' ',
          trigger: '#',
        }}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'link',
            'embedded',
            'emoji',
            'image',
            //'history',
          ],
          inline: {
            inDropdown: false,
            options: [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              //   'monospace',
              //   'superscript',
              //   'subscript',
            ],
          },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { uploadCallback: onFileUpload, alt: { present: true, mandatory: true } },
        }}
        editorStyle={{ height: '200px', fontSize: defaultFontSize }}
        editorClassName={editorClass}
      />
    </div>
  );
}

export default WYSIWYGEditor;
